<template>
  <section id="timezone-selector">
    <vs-select
      :label="timeZoneLabel"
      v-bind:value="value"
      v-on:input="$emit('input', $event)"
      autocomplete
      id="timezone-select"
      class="w-full"
    >
      <vs-select-item
        :key="key"
        :value="timeZone"
        :text="timeZone.text"
        v-for="(timeZone, key) in timeZones"
      />
    </vs-select>
  </section>
</template>

<script>
import { mapState } from 'vuex'

import timeZones from '../constants/_TIME_ZONES.json'

export default {
  name: 'TimeZoneSelector',
  props: {
    value: Object,
    showLabel: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      timeZones
    }
  },
  computed: {
    ...mapState(['lang', 'languageSelected']),
    timeZoneLabel() {
      return this.showLabel
        ? this.lang.botMaker.addBot.timeZone.title[this.languageSelected]
        : ''
    }
  }
}
</script>
